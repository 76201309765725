import React, { useState } from "react";
import useSearch from "./hooks/useSearch";
import CssBaseline from '@mui/material/CssBaseline';
import ReviewCard from "./ReviewCard";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from "react-infinite-scroll-component";
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export function SearchAppBar(props) {
  const {  window, searchOnChange, searchValue } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, top: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor={'top'}
            open={state['top']}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Your Briefly
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={searchOnChange}
              value={searchValue}
            />
          </Search>
        </Toolbar>
      </AppBar>
    </Slide>
  );
}


export default function App(props) {
  const [query, setQuery] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [history, setHistory] = useState([]);
  const { isLoading, error, entries, hasMore } = useSearch(query, pageNum, history);

  const [timers, setTimers] = React.useState({});
  const entryObserver = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      intersectionEntries => {
        intersectionEntries.forEach(entry => {
          setTimers(timers => {
            const id = entry.target.dataset.id;
            const timer = timers[id] || { total: 0, start: null };
  
            if (entry.isIntersecting) {
              // Start the timer
              timer.start = new Date();
            } else if (timer.start) {
              // Stop the timer and add to the total
              timer.total += new Date().getTime() - timer.start.getTime();
              timer.start = null;
            }
  
            return { ...timers, [id]: timer };
          });
        });
      },
      { threshold: 0.75 }
    );
    entryObserver.current = observer;
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setQuery(e.target.value);
      setPageNum(1);
    }
  };

  const fetchMoreData = () => {
    console.log("Fetch called");
    setHistory( entries.filter((entry, index) => timers[index].total >= 1000 ));
    if (!isLoading) {setPageNum((prev) => prev + 1);}
  };

  return (
    <React.Fragment>
      <CssBaseline enableColorScheme />
      <div className="App">
        <SearchAppBar searchOnChange={handleChange} searchValue={query}  />
        <Offset />
        <Container  fixed>   
          <InfiniteScroll
            dataLength={entries.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
          >
            <Stack spacing={2}>
            {entries.map((entry, i) => {
                return <div  key={i}><ReviewCard 
                title={entry} 
                entryId={i}
                observer={entryObserver.current}
                timer={timers[i] || { total: 0, start: null }} /></div>;
            })}
            </Stack>
          </InfiniteScroll>   
        </Container>
        <div>{error && "Error..."}</div>
      </div>
    </React.Fragment>
  );
}
